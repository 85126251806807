<template>
  <div class="container">
    <!-- <two-fa-qr-code
      v-if="googleTfaEnabled"
      v-on:qr-code-accepted="qrCodeAccepted"
    ></two-fa-qr-code> -->
    <div class="card card-custom example example-compact">
      <form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body">
          <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">
              {{ $t("FORM.FIRST_NAME") }}
            </label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <input
                type="text"
                class="form-control"
                placeholder=""
                v-model="$v.form.firstName.$model"
                :state="validateState('firstName')"
                aria-describedby="input-1-live-feedback"
              />
              <div class="validation error" v-if="$v.form.firstName.$error">
                {{ $t("AUTH.VALIDATION.REQUIRED_FIELD") }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">
              {{ $t("FORM.LAST_NAME") }}
            </label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <input
                type="text"
                class="form-control"
                placeholder=""
                v-model="$v.form.lastName.$model"
                :state="validateState('lastName')"
                aria-describedby="input-1-live-feedback"
              />
              <div class="validation error" v-if="$v.form.lastName.$error">
                {{ $t("AUTH.VALIDATION.REQUIRED_FIELD") }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">
              {{ $t("FORM.EMAIL") }}
            </label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <input
                type="text"
                class="form-control"
                placeholder=""
                v-model="$v.form.email.$model"
                :state="validateState('email')"
                aria-describedby="email-help"
              />
              <small v-if="isNew" id="email-help" class="form-text text-muted">
                {{ $t("COMPANY.MAIL_INFO_MESSAGE") }}
              </small>
              <template v-if="$v.form.email.$error">
                <div class="validation error" v-if="!$v.form.email.required">
                  {{ $t("AUTH.VALIDATION.REQUIRED_FIELD") }}
                </div>
                <div class="validation error" v-if="!$v.form.email.email">
                  {{
                    $t("AUTH.VALIDATION.INVALID", { name: $t("FORM.EMAIL") })
                  }}
                </div>
              </template>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">{{
              $t("FORM.PHONE")
            }}</label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <input
                type="text"
                class="form-control"
                placeholder=""
                v-model="$v.form.phone.$model"
                :state="validateState('phone')"
                aria-describedby="input-1-live-feedback"
              />
              <div class="validation error" v-if="$v.form.phone.$error">
                {{ $t("AUTH.VALIDATION.REQUIRED_FIELD") }}
              </div>
            </div>
          </div>
          <!-- <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">{{
              $t("FORM.POS_NUMBER")
            }}</label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <input
                type="text"
                class="form-control"
                placeholder=""
                v-model="$v.form.posNumber.$model"
                :state="validateState('posNumber')"
                aria-describedby="input-1-live-feedback"
              />
            </div>
          </div> -->
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-lg-9 ml-lg-auto d-flex justify-content-end">
              <button type="submit" class="btn btn-success mr-2">
                {{ buttonTitle }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
// import TwoFaQrCode from "./TwoFaQrCode.vue";

export default {
  mixins: [validationMixin],
  // components: { TwoFaQrCode },
  props: ["showDeleteButton"],
  data() {
    return {
      user: {},
      form: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null
        // googleTfaEnabled: false,
        // posNumber: null
      },
      isNew: true,
      buttonTitle: null
    };
  },
  validations: {
    form: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      phone: { required }
      // posNumber: {}
    }
  },
  computed: {
    ...mapGetters({
      activeUserId: "activeUserId",
      selectedUser: "user/SELECTED_USER",
      isBranch: "isBranch",
      isDistributor: "isDistributor",
      isMerchant: "isMerchant"
    }),
    googleTfaEnabled() {
      const isActiveUser = this.user?.id === this.activeUserId;
      return isActiveUser && this.form.googleTfaEnabled === false;
    }
  },
  watch: {
    selectedUser(newUser) {
      this.user = newUser;
      this.setForm();
    }
  },
  mounted() {
    this.user = this.selectedUser;
    this.setForm();
  },
  methods: {
    setForm() {
      const user = this.user;

      if (user?.id) {
        this.isNew = false;
        this.buttonTitle = this.$t("FORM.UPDATE");
        this.form.firstName = user.firstName;
        this.form.lastName = user.lastName;
        this.form.email = user.email;
        this.form.phone = user.phone;
        this.form.googleTfaEnabled = user.googleTfaEnabled;
        // this.form.posNumber = user?.posNumber || null;
      } else {
        this.buttonTitle = this.$t("FORM.SUBMIT");
        if (!this.isDistributor) {
          this.form.isActive = false;
        }
      }
    },
    validateState(title) {
      const { $dirty, $error } = this.$v.form[title];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.$emit("submit", this.form);
    },
    qrCodeAccepted() {
      this.form.googleTfaEnabled = true;
    }
  }
};
</script>

<style scoped>
.store-logo {
  height: 40px;
}
.google {
  margin-top: 25px;
  border: 1px solid rgba(196, 195, 195, 0.596);
}
</style>
