<template>
  <user-form
    :user="settingsUser"
    v-on:delete-user="onDeleteUser"
    v-on:submit="onSubmit"
    :showDeleteButton="showDeleteButton"
  ></user-form>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import { validationMixin } from "vuelidate";
import { mapActions, mapGetters } from "vuex";
import Api from "../../../services/api";
import UserForm from "../../../components/forms/UserForm.vue";

export default {
  mixins: [validationMixin],
  components: { UserForm },
  props: ["profile", "deleteable"],
  data() {
    return {
      subTitle: null,
      settingsUser: {}
    };
  },
  computed: {
    ...mapGetters({
      activeUser: "auth/activeUser",
      selectedUser: "user/SELECTED_USER",
      selectedUserId: "user/SELECTED_USER_ID"
    }),
    showDeleteButton() {
      return !this.profile && this.deleteable;
    },
    submitMessage() {
      const message = this.selectedUserId ? "FORM.UPDATED" : "FORM.SUBMITTED";
      return this.$t(message);
    }
  },
  watch: {
    selectedUser(newUser) {
      this.settingsUser = newUser;
    }
  },
  created() {
    if (this.profile) {
      this.setCurrentUserSelectedUser();
    }
  },
  mounted() {
    const title = this.profile
      ? this.$t("ROUTES.PROFILE")
      : this.$t("ROUTES.USERS");

    this.subTitle = this.selectedUserId
      ? this.$t("MENU.EDIT")
      : this.$t("MENU.NEW") + " " + this.$t("COMPANY.USER");

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("ROUTES.SETTINGS") },
      { title: title }, //"Kullanıcılar" },
      { title: this.subTitle }
    ]);
  },
  beforeRouteLeave(to, from, next) {
    this.clearSelectedUser();
    next();
  },
  methods: {
    ...mapActions({
      clearSelectedUser: "user/CLEAR_SELECTED_USER",
      deleteSelectedUser: "user/DELETE_USER",
      setCurrentUserSelectedUser: "user/SET_CURRENT_USER_SELECTED_USER",
      toggleUserActiveStatus: "user/TOGGLE_USER_ACTIVE_STATUS"
    }),
    onSubmit(user) {
      const apiAction = this.selectedUserId ? Api.put : Api.post;
      let endPoint = "user";
      endPoint += this.selectedUserId ? "s/" + this.selectedUserId : "s";

      const successTitle = this.$t("FORM.SUCCESS_TITLE");
      const errorTitle = this.$t("FORM.ERROR_TITLE");
      const successMessage = this.$t("COMPANY.USER") + this.submitMessage;
      const errorMessage = this.$t("FORM.ERROR");

      apiAction(endPoint, user)
        .then(() => {
          this.$root.$bvToast.toast(successMessage, {
            title: successTitle,
            variant: "success",
            autoHideDelay: 5000
          });
          this.$router.replace({ name: "settings-users" });
        })
        .catch(() => {
          this.$root.$bvToast.toast(errorMessage, {
            title: errorTitle,
            variant: "danger",
            autoHideDelay: 5000
          });
        });
    },
    onDeleteUser({ id, isActive }) {
      /* this.deleteSelectedUser(id).then(() => {
        this.$router.replace({ name: "settings-users" });
      }); */
      this.toggleUserActiveStatus({ userId: id, isActive })
        .then(() => {
          this.$root.$bvToast.toast(this.$t("FORM.SUBMITTED"), {
            title: this.$t("FORM.SUCCESS_TITLE"),
            variant: "success",
            autoHideDelay: 5000
          });
          this.$router.replace({ name: "settings-users" });
        })
        .catch(err => {
          const msg = err?.response?.data?.message || this.$t("FORM.ERROR");
          this.$root.$bvToast.toast(msg, {
            title: this.$t("FORM.ERROR_TITLE"),
            variant: "danger",
            autoHideDelay: 5000
          });
        });
    }
  }
};
</script>
